/**
 * Page-specific Javascript file.  Should generally be included as a separate asset bundle in your page template.
 * example: {{ assets.js('js/pages/sign-in-or-register') | raw }}
 *
 * This script depends on uf-table.js, moment.js, handlebars-helpers.js
 *
 * Target page: /groups/g/{slug}
 */

$(document).ready(function() {
    var el = $("#helpMenu");

    $('#helpMenu').find('ul').click(function(e) {
      e.stopPropagation();
    });

    el.find('.helpModalLink').click(function(e) {
        e.preventDefault();

        $("body").ufModal({
            sourceUrl: site.uri.public + "/modals/help-guide"
        });
    });
});
